export const accessTokenKey = "admin_access_token";
export const tokenKey = "USER_TOKEN";
export const googleLoginKey = "LOGIN_WITH_GOOGLE";
export const googleLoginValue = "YES";
export const value = "AUTH_TOKEN";

export const roles = {
	CUSTOMER: "customer",
	INSTALLER: "installer",
	ADMIN: "admin",
	INSTALLER_ADMIN: "installer-admin",
	TECHNICIAN: "technician",
	REPRESENTATIVE: "representative",
	MANAGER: "manager",
	OFFICE_MANAGER: "office-manager",

	SUPER_ADMIN: "super-admin",
	DIRECTOR: "director",
	ADMIN_MANGER: "admin-manager",
	PSL: "psl",
};
